const SocialLogo = ({ logoName = "instagram", isDark = false }) => {
  const renderLogo = () => {
    switch (logoName.toLowerCase()) {
      case "linkedin":
        return (
          <a
            href="http://linkedin.com/company/storymarq-productions"
            rel="noreferrer"
            target="_blank"
            className="hover:scale-125"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill={`${isDark} ? : "#333" : "white"`}
            >
              <g clip-path="url(#clip0_172_262)">
                <path
                  d="M13.0221 0.164795C6.02717 0.164795 0.361572 5.83039 0.361572 12.8253C0.361572 19.8203 6.02717 25.4859 13.0221 25.4859C20.0171 25.4859 25.6827 19.8203 25.6827 12.8253C25.6827 5.83039 20.0171 0.164795 13.0221 0.164795ZM9.52465 19.1477H6.89758V10.7047H9.52465V19.1477ZM8.21111 9.54943C7.37235 9.54943 6.69185 8.86892 6.69185 8.03016C6.69185 7.1914 7.37235 6.5109 8.21111 6.5109C9.04988 6.5109 9.73038 7.1914 9.73038 8.03016C9.73038 8.86892 9.04988 9.54943 8.21111 9.54943ZM19.3524 19.1477H16.7253V15.0409C16.7253 14.0597 16.7095 12.8016 15.3643 12.8016C14.0191 12.8016 13.7897 13.8698 13.7897 14.9697V19.1477H11.1705V10.7047H13.6868V11.86H13.7264C14.0745 11.1953 14.9291 10.499 16.211 10.499C18.8697 10.499 19.3603 12.2477 19.3603 14.5187V19.1477H19.3524Z"
              fill={`${isDark ? "#333" : "white"}`}

                />
              </g>
              <defs>
                <clipPath id="clip0_172_262">
                  <rect
                    width="25.3211"
                    height="25.3211"
                fill={`${isDark ? "#333" : "white"}`}

                    transform="translate(0.361572 0.164795)"
                  />
                </clipPath>
              </defs>
            </svg>
          </a>
        );

      case "instagram":
        return (
          <a
            href="https://www.instagram.com/storymarqnyc/"
            target="_blank"
            className="hover:scale-125"
            rel="noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="26"
              viewBox="0 0 27 26"
              fill={`${isDark} ? : "#333" : "white"`}
            >
              <g clip-path="url(#clip0_172_263)">
                <path
                  d="M13.3705 15.2704C14.7157 15.2704 15.8077 14.1863 15.8077 12.8411C15.8077 11.4959 14.7157 10.4119 13.3705 10.4119C12.0253 10.4119 10.9333 11.4959 10.9333 12.8411C10.9333 14.1863 12.0253 15.2704 13.3705 15.2704Z"
              fill={`${isDark ? "#333" : "white"}`}

                />
                <path
                  d="M13.3546 0.164795C6.35969 0.164795 0.694092 5.83039 0.694092 12.8253C0.694092 19.8203 6.35969 25.4859 13.3546 25.4859C20.3496 25.4859 26.0152 19.8203 26.0152 12.8253C26.0152 5.83039 20.3496 0.164795 13.3546 0.164795ZM19.6849 17.6522C19.6849 18.483 19.0123 19.1556 18.1815 19.1556H8.52781C7.69696 19.1556 7.02437 18.483 7.02437 17.6522V7.99851C7.02437 7.16766 7.69696 6.49507 8.52781 6.49507H18.1815C19.0123 6.49507 19.6849 7.16766 19.6849 7.99851V17.6522Z"
              fill={`${isDark ? "#333" : "white"}`}

                />
                <path
                  d="M17.224 12.8412C17.224 14.9619 15.499 16.6869 13.3704 16.6869C11.2419 16.6869 9.51689 14.9619 9.51689 12.8412C9.51689 12.493 9.56436 12.1528 9.6514 11.8284H8.46448V17.3357C8.46448 17.5335 8.62274 17.6918 8.82056 17.6918H17.9124C18.1102 17.6918 18.2685 17.5335 18.2685 17.3357V11.8284H17.0816C17.1686 12.1528 17.2161 12.493 17.2161 12.8412H17.224Z"
              fill={`${isDark ? "#333" : "white"}`}

                />
              </g>
              <defs>
                <clipPath id="clip0_172_263">
                  <rect
                    width="25.3211"
                    height="25.3211"
                    fill="white"
                    transform="translate(0.694092 0.164795)"
                  />
                </clipPath>
              </defs>
            </svg>
          </a>
        );
      default:
        return (
          <a href="http://linkedin.com/company/storymarq-productions" rel="noreferrer"
            target="_blank" className="hover:scale-125">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="26"
              viewBox="0 0 27 26"
              fill={`${isDark ? "#333" : "white"}`}
            >
              <g clip-path="url(#clip0_172_263)">
                <path
                  d="M13.3705 15.2704C14.7157 15.2704 15.8077 14.1863 15.8077 12.8411C15.8077 11.4959 14.7157 10.4119 13.3705 10.4119C12.0253 10.4119 10.9333 11.4959 10.9333 12.8411C10.9333 14.1863 12.0253 15.2704 13.3705 15.2704Z"
              fill={`${isDark ? "#333" : "white"}`}

                />
                <path
                  d="M13.3546 0.164795C6.35969 0.164795 0.694092 5.83039 0.694092 12.8253C0.694092 19.8203 6.35969 25.4859 13.3546 25.4859C20.3496 25.4859 26.0152 19.8203 26.0152 12.8253C26.0152 5.83039 20.3496 0.164795 13.3546 0.164795ZM19.6849 17.6522C19.6849 18.483 19.0123 19.1556 18.1815 19.1556H8.52781C7.69696 19.1556 7.02437 18.483 7.02437 17.6522V7.99851C7.02437 7.16766 7.69696 6.49507 8.52781 6.49507H18.1815C19.0123 6.49507 19.6849 7.16766 19.6849 7.99851V17.6522Z"
              fill={`${isDark ? "#333" : "white"}`}

                />
                <path
                  d="M17.224 12.8412C17.224 14.9619 15.499 16.6869 13.3704 16.6869C11.2419 16.6869 9.51689 14.9619 9.51689 12.8412C9.51689 12.493 9.56436 12.1528 9.6514 11.8284H8.46448V17.3357C8.46448 17.5335 8.62274 17.6918 8.82056 17.6918H17.9124C18.1102 17.6918 18.2685 17.5335 18.2685 17.3357V11.8284H17.0816C17.1686 12.1528 17.2161 12.493 17.2161 12.8412H17.224Z"
              fill={`${isDark ? "#333" : "white"}`}

                />
              </g>
              <defs>
                <clipPath id="clip0_172_263">
                  <rect
                    width="25.3211"
                    height="25.3211"
                    fill="white"
                    transform="translate(0.694092 0.164795)"
                  />
                </clipPath>
              </defs>
            </svg>
          </a>
        );
    }
  };
  return renderLogo();
};

export default SocialLogo;

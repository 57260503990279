const Popup = ({ children, isOpen, onClose }) => {
  return (
    <div
      onClick={onClose}
      className={`w-screen bg-black/25 h-screen fixed top-0 left-0 z-50 transition-all duration-300 ease-in-out flex items-center justify-center ${
        isOpen
          ? "opacity-100 pointer-events-auto"
          : "opacity-0 pointer-events-none"
      }`}
    >
      {isOpen && (
        <div onClick={(e) => e.stopPropagation()} className="w-max mt-[80px]">
          {children}
        </div>
      )}
    </div>
  );
};

export default Popup;

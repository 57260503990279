import calendlyLinks from "constants/calendlyLinks";
import SecondaryLogo from "./SecondaryLogo";
import SocialLogo from "./SocialLogo";

const Footer = () => {
  const _renderContactSection = () => {
    return (
      <div className="flex top-0 w-full gap-1 flex-col text-white">
        <a
          href={calendlyLinks.georgia}
          className="border text-white bg-orange-primary max-w-[300px] md:mt-8 lg:mt-0 mb-3 border-orange-primary h-10 w-full lg:w-44 rounded-[4px] text-sm text-center flex justify-center items-center hover:border-white hover:bg-transparent"
        >
          Get In Touch
        </a>
        <div href="/" className="opacity-40 cursor-default">
          Brooklyn, NY, USA
        </div>
        <a
          href="mailto:info@storymarq.com"
          className="opacity-40 hover:opacity-100 text-sm sm:text-lg"
        >
          info@storymarqnyc.com
        </a>
        <div className="flex flex-row w-fit mt-3 gap-3">
          <SocialLogo logoName="linkedin" />
          <SocialLogo logoName="instagram" />
        </div>
      </div>
    )
  }
  return (
    <div className="h-fit w-full overflow-hidden bg-[#333] py-8 px-4 md:px-24">
      <div className="grid grid-cols-2 monitor:max-w-monitorWidth monitor:mx-auto grid-rows- gap-10 lg:grid-cols-3 w-full">
        <div className="h-fit max-w-[240px] w-auto items-start lg:w-fit flex flex-col gap-4 md:gap-0">
          <SecondaryLogo />
          <div className="lg:hidden block w-full">
            {_renderContactSection()}
          </div>
        </div>

        <div className="flex flex-col col-span-1 gap-2 text-white">
          <div className="font-semibold -mt-1  text-lg">Quick Links</div>
          <a
            href="#client-testimonials"
            className="opacity-40 hover:opacity-100"
          >
            Testimonials
          </a>
          <a href="#our-process" className="opacity-40 hover:opacity-100">
            Process
          </a>
          <a href="#our-story" className="opacity-40 hover:opacity-100">
            Our Story
          </a>
          <a href="#case-studies" className="opacity-40 hover:opacity-100">
            Case Studies
          </a>
          <a href="#people" className="opacity-40 hover:opacity-100">
            People
          </a>
        </div>
        <div className="lg:block hidden">
          {_renderContactSection()}
        </div>

      </div>
      <div className="text-white text-center w-full mt-10">
        Copyright © 2024 Storymarq Productions. All rights reserved.
      </div>
    </div>
  );
};

export default Footer;

import caseStudiesData from "constants/case-study-data";
import CaseStudyCard from "./CaseStudyCard";

const CaseStudies = () => {
  return (
    <>
      <div id="case-studies" className="-translate-y-20" />
      <section className=" lg:mx-16 lg:my-8 flex flex-col gap-[1px] monitor:max-w-monitorWidth monitor:mx-auto">
        <div className="uppercase font-bebas-neue font-bold text-center text-5xl text-black mb-7 mt-7 sm:mt-0">
          case studies
        </div>
        {caseStudiesData.map((cardData) => {
          return <CaseStudyCard cardData={cardData} />;
        })}
      </section>
    </>
  );
};

export default CaseStudies;

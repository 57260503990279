// Use require() if the asset is internal (stored in /assets) otherwise use direct link
// ul = unordered list (aka bullet point list)
// Update the component to include ordered lists (numeral or alphabetical) if needed
// import riseVid from "../assets/homepage/case_studies/"

const caseStudiesData = [
  {
    bgVideo: {
      link: require("assets/homepage/case_studies/Nature_Conservancy.mp4"),
      alt: "Drone shot of Rise headquarters",
      logo: require("assets/homepage/case_studies/nature.png"),
      poster: require("assets/homepage/case_studies/nature_conservancy_poster.png"),
    },
    secondaryVideo: {
      link: require("assets/homepage/case_studies/nature_conservatory_2.mp4"),
      alt: "Woman farming",
    },
    gridType: 3,
    verticalImage: {
      link: require("assets/homepage/case_studies/vertical_image1.jpg"),
      alt: "Drone shot of orange fall trees",
    },
    horizontalImages: [
      {
        link: require("assets/homepage/case_studies/grid_img1_1.jpg"),
        alt: "People farming and recycling",
      },
      {
        link: require("assets/homepage/case_studies/grid_img1_2.jpg"),
        alt: "Woman preparing soil for farming",
      },
    ],
    heading: "Community Visioning",
    subHeading: "Creating a Safer Future Together",
    textBlocks: [
      {
        type: "regular",
        title: "Mission",
        body: "The Nature Conservancy awarded the Community Visioning Project Grant to the communities of Sidney, and Edgemere, New York. Historically having been significantly affected by climate change and natural disaster, this funding allowed the two communities to collaborate with experts to reimagine their infrastructure and create a safer future together.",
      },
      {
        type: "regular",
        title: "Outcome",
        body: "We created a video that clearly communicated the scope of the grant’s implementation, its vital importance to these communities, and provided inspiration to see how individual voices are important in the conservation and protection of natural resources.",
      },
      {
        type: "regular",
        title: "Impact",
        body: "Raised awareness about the project and created discussion amongst communities and key stakeholders.",
      },
      {
        type: "ul",
        title: "Services",
        body: [
          "Story development and research",
          "Interview participant selection",
          "Finished full length video w/captioning and graphics",
          "Additional social videos",
        ],
      },
    ],
  },
  {
    bgVideo: {
      link: require("assets/homepage/case_studies/rise.mov"),
      alt: "Drone shot of Rise headquarters",
      logo: require("assets/homepage/case_studies/rise.png"),
      poster: require("assets/homepage/case_studies/rise_poster.png"),
    },
    secondaryVideo: {
      link: require("assets/homepage/case_studies/rise_2.mp4"),
      alt: "Woman farming",
    },
    gridType: 4,
    verticalImage: {
      link: require("assets/homepage/case_studies/vertical_image1.jpg"),
      alt: "Drone shot of orange fall trees",
    },
    horizontalImages: [
      {
        link: require("assets/homepage/case_studies/rise-grid-1.jpg"),
        alt: "Video shoot of an interview",
      },
      {
        link: require("assets/homepage/case_studies/rise-grid-2.jpg"),
        alt: "Video shoot ona  boat",
      },
      {
        link: require("assets/homepage/case_studies/rise-grid-3.jpg"),
        alt: "Video shoot on a farm",
      },
      {
        link: require("assets/homepage/case_studies/rise-grid-4.jpg"),
        alt: "Video shoot in a building",
      },
    ],
    heading: "Greater rockaway coastal resilience plan",
    subHeading: "Ensuring sustainability",
    textBlocks: [
      {
        type: "regular",
        title: "Mission",
        body: 'The "Greater Rockaway" Coastal Resilience Plan was initiated in 2020 by RISE, with support from New York City Department of Parks and Recreation, New York State Department of Environmental Conservation, US Army Corps of Engineers, as well as technical support from WXY Architecture & Urban Planning, and eDesign Dynamics. A complex initiative with a simple goal, needed to be communicated.',
      },
      {
        type: "regular",
        title: "Outcome",
        body: "Combining interviews with leaders, students and community partners with supporting material we told a story of celebration, inspiration, and hope. We captured their excitement for the future and the desire for sustainability and conservation education.",
      },
      {
        type: "regular",
        title: "Impact",
        body: "Raised local awareness and support. Students presented the video at the UIA 2023 World Congress of Architects in Copenhagen, where it was well-received.",
      },
      {
        type: "ul",
        title: "Services",
        body: [
          "Story development and research",
          "Interview participant selection",
          "Finished full length video w/captioning and graphics",
          "Additional social videos",
        ],
      },
    ],
  },
];

export default caseStudiesData;

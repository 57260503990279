import MarqueeLogoLinks from "../../constants/marquee-logo-links";

const ClientLogosMarquee = () => {
  return (
    <div class="relative flex overflow-x-hidden">
      <div class="flex flex-row gap-16 my-6 lg:my-16 animate-marquee whitespace-nowrap">
        <img
          className="max-h-8 lg:max-h-12 w-auto"
          src="https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/65591944403c557189d146b4_Adweek.svg"
          loading="lazy"
          height="50"
          alt=""
        />
        {MarqueeLogoLinks.map((link, index) => {
          return (
            <img
              className="max-h-10 lg:max-h-12 w-auto"
              src={link}
              loading="lazy"
              height="50"
              alt=""
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ClientLogosMarquee;

const PrimaryLogo = ({ isDark }) => {
    return (
        <a href="/">
            {
                isDark ?
                    <svg width="213" height="37" viewBox="0 0 213 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_29_354)">
                            <path d="M17.4179 3.68978e-07L4.98954 0.00595851C2.23344 0.00695324 0 2.0159 0 4.49357L0.00897799 22.6109C0.00897799 25.9575 0.908356 27.6318 5.26635 28.8472L22.4005 34.0667L22.4105 4.48365C22.4085 2.00597 20.174 -0.00099351 17.4179 3.68978e-07ZM14.2911 23.7658C10.9434 25.0856 7.16304 23.5195 5.73302 20.2633V20.2613L5.71304 20.2692C5.67406 20.1709 5.63209 20.0746 5.59311 19.9763C3.29469 14.2096 6.13475 7.68426 11.9357 5.40023C12.5843 5.14701 13.2438 4.95435 13.9054 4.82327C13.8414 4.862 13.7765 4.90371 13.7115 4.94939C12.2915 5.90868 10.6306 8.0507 10.4618 11.1312C13.5836 10.4728 16.8194 12.1262 18.0325 15.1719C19.3876 18.5721 17.7128 22.4202 14.2911 23.7658Z" fill="#FF4D00" />
                            <path d="M37.0918 32.8861L37.092 33.5935C37.0924 34.4874 36.4122 35.1096 35.4422 35.11L34.8008 35.1103L34.8015 36.8982L33.589 36.8987L33.5867 31.3795L35.4328 31.3788C36.4028 31.3784 37.0913 31.9999 37.0918 32.8861ZM35.8793 32.8322C35.8792 32.6145 35.7383 32.4669 35.5193 32.467L34.7996 32.4673L34.8003 34.022L35.5121 34.0217C35.7389 34.0216 35.8797 33.8738 35.8796 33.6484L35.8793 32.8322Z" fill="#3B3F3F" />
                            <path d="M52.204 34.901L51.6251 34.9012L51.626 36.8912L50.4135 36.8917L50.4111 31.3726L52.2573 31.3718C53.2272 31.3714 53.9158 31.9852 53.9162 32.8791L53.9164 33.3922C53.9166 33.8819 53.7057 34.2939 53.3615 34.5585L54.0822 36.8901L52.815 36.8907L52.204 34.901ZM51.6246 33.8129L52.3365 33.8126C52.5633 33.8125 52.7041 33.6725 52.704 33.4471L52.7037 32.8252C52.7036 32.5998 52.5627 32.46 52.3437 32.46L51.6241 32.4604L51.6246 33.8129Z" fill="#3B3F3F" />
                            <path d="M68.2933 35.4306L68.2922 32.8187C68.2919 31.8937 69.0113 31.2482 70.1377 31.2477C71.2563 31.2473 71.9841 31.8921 71.9844 32.8172L71.9855 35.4291C71.9859 36.3541 71.2666 36.9996 70.148 37C69.0215 37.0005 68.2938 36.3556 68.2933 35.4306ZM70.7731 35.4451L70.772 32.8021C70.7719 32.5223 70.5841 32.3358 70.146 32.336C69.6923 32.3361 69.5047 32.5228 69.5047 32.8026L69.5058 35.4456C69.506 35.7254 69.6939 35.9119 70.1397 35.9118C70.5856 35.9116 70.7733 35.7249 70.7731 35.4451Z" fill="#3B3F3F" />
                            <path d="M89.912 32.9263L89.913 35.3049C89.9133 36.2299 89.1941 36.8754 88.138 36.8758L86.3388 36.8766L86.3365 31.3574L88.1279 31.3567C89.1839 31.3563 89.9116 32.0012 89.912 32.9263ZM88.6996 32.9112C88.6994 32.6314 88.5194 32.4448 88.2143 32.445L87.5494 32.4452L87.5508 35.7878L88.2079 35.7875C88.5208 35.7874 88.7007 35.6008 88.7006 35.321L88.6996 32.9112Z" fill="#3B3F3F" />
                            <path d="M104.211 35.4155L104.209 31.35L105.422 31.3495L105.423 35.4306C105.424 35.7104 105.604 35.8969 105.987 35.8967C106.378 35.8965 106.558 35.7099 106.558 35.4301L106.556 31.349L107.768 31.3485L107.77 35.414C107.771 36.339 107.075 36.9846 105.995 36.985C104.916 36.9855 104.211 36.3405 104.211 35.4155Z" fill="#3B3F3F" />
                            <path d="M122.068 35.4081L122.067 32.7962C122.066 31.8712 122.786 31.2256 123.897 31.2252C125.007 31.2247 125.735 31.8697 125.736 32.7947L125.736 33.4866L124.508 33.4871L124.507 32.7797C124.507 32.4999 124.304 32.3133 123.905 32.3135C123.49 32.3137 123.279 32.5004 123.279 32.7802L123.28 35.4231C123.281 35.703 123.492 35.8895 123.899 35.8893C124.305 35.8891 124.509 35.7024 124.509 35.4226L124.508 34.7152L125.736 34.7147L125.737 35.4066C125.737 36.3316 125.018 36.9771 123.907 36.9776C122.796 36.978 122.068 36.3331 122.068 35.4081Z" fill="#3B3F3F" />
                            <path d="M143.295 32.422L142.145 32.4225L142.147 36.8534L140.935 36.8539L140.933 32.423L139.775 32.4235L139.775 31.3352L143.295 31.3337L143.295 32.422Z" fill="#3B3F3F" />
                            <path d="M157.436 31.3278L158.649 31.3273L158.651 36.8464L157.439 36.8469L157.436 31.3278Z" fill="#3B3F3F" />
                            <path d="M172.995 35.3869L172.994 32.775C172.994 31.8499 173.713 31.2044 174.84 31.204C175.958 31.2035 176.686 31.8484 176.687 32.7734L176.688 35.3853C176.688 36.3103 175.969 36.9558 174.85 36.9563C173.724 36.9568 172.996 36.3119 172.995 35.3869ZM175.475 35.4013L175.474 32.7583C175.474 32.4785 175.286 32.292 174.848 32.2922C174.394 32.2924 174.207 32.4791 174.207 32.7589L174.208 35.4019C174.208 35.6817 174.396 35.8682 174.842 35.8681C175.288 35.8678 175.475 35.6811 175.475 35.4013Z" fill="#3B3F3F" />
                            <path d="M194.606 31.3124L194.608 36.8315L193.544 36.8319L192.096 33.692L192.097 36.8325L191.041 36.833L191.039 31.3139L192.095 31.3134L193.543 34.4688L193.542 31.3128L194.606 31.3124Z" fill="#3B3F3F" />
                            <path d="M208.929 35.193L208.928 34.9754L210.141 34.9749L210.141 35.3169C210.141 35.7133 210.454 35.8843 210.791 35.8841C211.057 35.8841 211.354 35.7206 211.354 35.3475C211.353 34.3603 209.03 34.5945 209.029 32.8066C209.029 31.866 209.639 31.1895 210.765 31.189C211.876 31.1885 212.494 31.8024 212.494 32.7352L212.495 33.0773L211.282 33.0778L211.282 32.658C211.282 32.417 211.047 32.2772 210.758 32.2773C210.484 32.2774 210.249 32.4252 210.249 32.7439C210.25 33.5368 212.573 33.357 212.574 35.2382C212.574 36.2487 211.902 36.9409 210.768 36.9413C209.633 36.9418 208.929 36.2424 208.929 35.193Z" fill="#3B3F3F" />
                            <path d="M31.5453 17.9032V17.0115H35.4871V18.349C35.4871 20.2598 36.865 21.2151 38.7879 21.2151C40.5825 21.2151 41.9283 20.2279 41.9283 18.4127C41.9283 13.6359 31.994 14.9096 31.994 7.64876C31.994 3.92284 34.3975 1.31152 38.7237 1.31152C43.0821 1.31152 45.5497 3.76366 45.5497 7.4577V8.79524H41.6079V7.1711C41.6079 5.83357 40.4544 4.94191 38.7237 4.94191C37.0573 4.94191 35.9358 5.83356 35.9358 7.42593C35.9358 11.4703 45.8701 10.3238 45.8701 18.0306C45.9021 22.0113 43.2423 24.7501 38.7237 24.7501C34.2694 24.7501 31.5453 22.0431 31.5453 17.9032Z" fill="#3B3F3F" />
                            <path d="M65.2583 5.38782H60.0667V24.3042H56.1252V5.38782H50.9336V1.75732H65.2583V5.38782Z" fill="#3B3F3F" />
                            <path d="M70.3864 18.3808V7.7444C70.3864 3.92284 73.1104 1.31152 77.6289 1.31152C82.1475 1.31152 84.8715 3.92284 84.8715 7.7444V18.3808C84.8715 22.1705 82.1475 24.7501 77.6289 24.7501C73.1104 24.7501 70.3864 22.1705 70.3864 18.3808ZM80.9297 18.4127V7.68064C80.9297 5.96098 80.0005 4.94191 77.6289 4.94191C75.2576 4.94191 74.3281 5.96098 74.3281 7.68064V18.4127C74.3281 20.1005 75.2576 21.1196 77.6289 21.1196C80.0005 21.1196 80.9297 20.1005 80.9297 18.4127Z" fill="#3B3F3F" />
                            <path d="M101.532 24.3042L98.5833 15.6103H95.1865V24.3042H91.2448V1.75732H98.4232C102.365 1.75732 104.961 4.14581 104.961 7.64878V9.7188C104.961 11.8525 103.999 13.5721 102.365 14.5912L105.665 24.3042H101.532ZM95.1865 11.9799H98.6475C100.218 11.9799 101.051 11.2155 101.051 9.84621V7.52148C101.051 6.15206 100.218 5.38782 98.6794 5.38782H95.1865V11.9799Z" fill="#3B3F3F" />
                            <path d="M119.959 16.502V24.3042H116.017V16.4383L110.184 1.75732H114.383L118.036 11.7251L121.689 1.75732H125.823L119.959 16.502Z" fill="#3B3F3F" />
                            <path d="M149.612 24.3042H146.247V7.61701L142.145 24.3042H139.293L135.191 7.52148V24.3042H131.858V1.75732H136.472L140.735 16.8842L144.997 1.75732H149.612V24.3042Z" fill="#3B3F3F" />
                            <path d="M166.18 19.4636H160.796L159.546 24.3042H155.701L161.052 1.75732H166.02L171.339 24.3042H167.43L166.18 19.4636ZM163.52 6.47054L161.629 15.8332H165.379L163.52 6.47054Z" fill="#3B3F3F" />
                            <path d="M187.779 24.3042L184.831 15.6103H181.434V24.3042H177.492V1.75732H184.671C188.612 1.75732 191.208 4.14581 191.208 7.64878V9.7188C191.208 11.8525 190.247 13.5721 188.612 14.5912L191.913 24.3042H187.779ZM181.434 11.9799H184.895C186.465 11.9799 187.298 11.2155 187.298 9.84621V7.52148C187.298 6.15206 186.465 5.38782 184.927 5.38782H181.434V11.9799Z" fill="#3B3F3F" />
                            <path d="M213 29.2977L205.149 24.7501C200.758 24.6863 198.098 22.1069 198.098 18.3808V7.7444C198.098 3.92284 200.822 1.31152 205.341 1.31152C209.859 1.31152 212.583 3.92284 212.583 7.7444V18.3808C212.583 20.4826 211.718 22.2342 210.18 23.3488L213 24.9729V29.2977ZM205.341 21.1196C207.712 21.1196 208.642 20.1005 208.642 18.4127V7.68064C208.642 5.96097 207.712 4.94191 205.341 4.94191C202.969 4.94191 202.04 5.96097 202.04 7.68064V18.4127C202.04 20.1005 202.969 21.1196 205.341 21.1196Z" fill="#3B3F3F" />
                        </g>
                        <defs>
                            <clipPath id="clip0_29_354">
                                <rect width="213" height="37" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                    :
                    <svg xmlns="http://www.w3.org/2000/svg" width="214" height="38" viewBox="0 0 214 38" fill="none">
                        <path d="M17.9123 0.660157L5.4912 0.666118C2.73671 0.667114 0.504578 2.67724 0.504578 5.15638L0.51355 23.2843C0.51355 26.6329 1.4124 28.3082 5.76785 29.5244L22.892 34.747L22.9019 5.14644C22.8999 2.66731 20.6668 0.659162 17.9123 0.660157ZM14.7873 24.4399C11.4416 25.7605 7.66343 24.1935 6.23425 20.9354V20.9334L6.21428 20.9413C6.17531 20.843 6.13337 20.7466 6.09441 20.6482C3.79734 14.8781 6.63574 8.34894 12.4333 6.06357C13.0815 5.81019 13.7406 5.61743 14.4018 5.48627C14.3379 5.52502 14.273 5.56675 14.2081 5.61246C12.7889 6.57231 11.129 8.71559 10.9602 11.7979C14.0802 11.1391 17.3141 12.7935 18.5266 15.841C19.8808 19.2432 18.207 23.0936 14.7873 24.4399Z" fill="white" />
                        <path d="M37.5746 33.5653L37.5748 34.2731C37.5752 35.1676 36.8954 35.7902 35.926 35.7905L35.2849 35.7909L35.2857 37.5798L34.0739 37.5803L34.0715 32.0579L35.9166 32.0571C36.886 32.0567 37.5742 32.6786 37.5746 33.5653ZM36.3628 33.5114C36.3627 33.2936 36.2219 33.1459 36.003 33.146L35.2837 33.1463L35.2844 34.7019L35.9959 34.7016C36.2225 34.7015 36.3632 34.5536 36.3631 34.3281L36.3628 33.5114Z" fill="white" />
                        <path d="M52.6781 35.5815L52.0995 35.5818L52.1004 37.5729L50.8886 37.5734L50.8862 32.051L52.7313 32.0503C53.7007 32.0499 54.3888 32.664 54.3893 33.5585L54.3894 34.0718C54.3897 34.5618 54.1788 34.9741 53.8349 35.2388L54.5552 37.5718L53.2887 37.5724L52.6781 35.5815ZM52.099 34.4928L52.8105 34.4925C53.0372 34.4924 53.1778 34.3523 53.1777 34.1268L53.1775 33.5045C53.1774 33.279 53.0366 33.1391 52.8176 33.1391L52.0984 33.1395L52.099 34.4928Z" fill="white" />
                        <path d="M68.758 36.1116L68.7569 33.4982C68.7566 32.5726 69.4755 31.9268 70.6013 31.9263C71.7192 31.9259 72.4466 32.5711 72.4469 33.4967L72.448 36.1101C72.4484 37.0357 71.7295 37.6816 70.6115 37.682C69.4858 37.6825 68.7584 37.0372 68.758 36.1116ZM71.2363 36.1262L71.2352 33.4816C71.2351 33.2017 71.0474 33.015 70.6096 33.0152C70.1562 33.0154 69.9686 33.2022 69.9687 33.4821L69.9698 36.1267C69.9699 36.4066 70.1577 36.5933 70.6033 36.5931C71.0489 36.5929 71.2365 36.4061 71.2363 36.1262Z" fill="white" />
                        <path d="M90.364 33.6057L90.365 35.9857C90.3653 36.9113 89.6465 37.5571 88.5911 37.5575L86.7929 37.5583L86.7906 32.0359L88.5809 32.0352C89.6363 32.0347 90.3636 32.6801 90.364 33.6057ZM89.1523 33.5906C89.1521 33.3106 88.9722 33.1239 88.6673 33.1241L88.0028 33.1243L88.0042 36.4688L88.6609 36.4686C88.9736 36.4684 89.1534 36.2818 89.1533 36.0018L89.1523 33.5906Z" fill="white" />
                        <path d="M104.655 36.0967L104.653 32.0288L105.865 32.0283L105.866 36.1118C105.866 36.3918 106.046 36.5784 106.429 36.5782C106.82 36.5781 107 36.3913 107 36.1113L106.998 32.0278L108.21 32.0273L108.212 36.0952C108.212 37.0208 107.517 37.6667 106.438 37.6672C105.359 37.6677 104.655 37.0223 104.655 36.0967Z" fill="white" />
                        <path d="M122.501 36.0891L122.5 33.4757C122.5 32.5502 123.218 31.9042 124.329 31.9038C125.439 31.9033 126.166 32.5487 126.166 33.4742L126.167 34.1665L124.939 34.167L124.939 33.4592C124.939 33.1792 124.736 32.9926 124.337 32.9927C123.923 32.9929 123.712 33.1797 123.712 33.4597L123.713 36.1042C123.713 36.3842 123.924 36.5708 124.331 36.5706C124.737 36.5705 124.94 36.3837 124.94 36.1037L124.94 35.3959L126.167 35.3954L126.168 36.0876C126.168 37.0132 125.449 37.6591 124.339 37.6596C123.229 37.66 122.501 37.0147 122.501 36.0891Z" fill="white" />
                        <path d="M143.716 33.1011L142.567 33.1016L142.569 37.5351L141.357 37.5356L141.355 33.1021L140.198 33.1026L140.197 32.0137L143.715 32.0122L143.716 33.1011Z" fill="white" />
                        <path d="M157.849 32.0064L159.061 32.0059L159.063 37.5282L157.851 37.5287L157.849 32.0064Z" fill="white" />
                        <path d="M173.399 36.0682L173.398 33.4547C173.397 32.5292 174.116 31.8832 175.242 31.8828C176.36 31.8823 177.087 32.5276 177.088 33.4531L177.089 36.0666C177.089 36.9921 176.37 37.6381 175.252 37.6385C174.127 37.639 173.399 36.9937 173.399 36.0682ZM175.877 36.0826L175.876 33.4381C175.876 33.1581 175.688 32.9715 175.25 32.9717C174.797 32.9719 174.609 33.1587 174.609 33.4387L174.611 36.0832C174.611 36.3632 174.798 36.5498 175.244 36.5496C175.69 36.5494 175.877 36.3626 175.877 36.0826Z" fill="white" />
                        <path d="M194.996 31.9907L194.999 37.5131L193.935 37.5135L192.488 34.3718L192.489 37.5141L191.434 37.5146L191.431 31.9922L192.487 31.9917L193.934 35.149L193.933 31.9911L194.996 31.9907Z" fill="white" />
                        <path d="M209.311 35.8736L209.311 35.6559L210.522 35.6554L210.523 35.9976C210.523 36.3942 210.835 36.5653 211.172 36.5651C211.437 36.565 211.735 36.4015 211.734 36.0282C211.734 35.0404 209.412 35.2747 209.411 33.4858C209.411 32.5447 210.02 31.8677 211.146 31.8672C212.256 31.8668 212.874 32.481 212.875 33.4143L212.875 33.7566L211.663 33.7571L211.663 33.3371C211.663 33.096 211.428 32.956 211.139 32.9561C210.865 32.9563 210.631 33.1042 210.631 33.423C210.631 34.2164 212.953 34.0365 212.954 35.9188C212.954 36.9299 212.282 37.6225 211.149 37.6229C210.015 37.6234 209.311 36.9236 209.311 35.8736Z" fill="white" />
                        <path d="M32.0315 18.5736V17.6814H35.9709V19.0196C35.9709 20.9316 37.3481 21.8875 39.2698 21.8875C41.0633 21.8875 42.4084 20.8997 42.4084 19.0834C42.4084 14.3038 32.4799 15.5783 32.4799 8.31313C32.4799 4.58502 34.8819 1.97217 39.2057 1.97217C43.5615 1.97217 46.0276 4.42575 46.0276 8.12196V9.46029H42.0882V7.8352C42.0882 6.49687 40.9353 5.60469 39.2057 5.60469C37.5402 5.60469 36.4194 6.49687 36.4194 8.09017C36.4194 12.1369 46.3478 10.9898 46.3478 18.7011C46.3799 22.6842 43.7216 25.4245 39.2057 25.4245C34.7539 25.4245 32.0315 22.716 32.0315 18.5736Z" fill="white" />
                        <path d="M65.7245 6.05109H60.536V24.9786H56.5968V6.05109H51.4082V2.41846H65.7245V6.05109Z" fill="white" />
                        <path d="M70.8496 19.0515V8.40883C70.8496 4.58502 73.572 1.97217 78.0879 1.97217C82.6038 1.97217 85.3263 4.58502 85.3263 8.40883V19.0515C85.3263 22.8434 82.6038 25.4245 78.0879 25.4245C73.572 25.4245 70.8496 22.8434 70.8496 19.0515ZM81.3868 19.0834V8.34503C81.3868 6.62436 80.4581 5.60469 78.0879 5.60469C75.718 5.60469 74.7891 6.62436 74.7891 8.34503V19.0834C74.7891 20.7722 75.718 21.7919 78.0879 21.7919C80.4581 21.7919 81.3868 20.7722 81.3868 19.0834Z" fill="white" />
                        <path d="M101.977 24.9786L99.0302 16.2796H95.6354V24.9786H91.696V2.41846H98.8702C102.809 2.41846 105.404 4.80835 105.404 8.31339V10.3846C105.404 12.5195 104.443 14.2402 102.809 15.2599L106.108 24.9786H101.977ZM95.6354 12.647H99.0944C100.664 12.647 101.496 11.8822 101.496 10.5121V8.18601C101.496 6.81579 100.664 6.05109 99.1262 6.05109H95.6354V12.647Z" fill="white" />
                        <path d="M120.393 17.1718V24.9786H116.454V17.108L110.625 2.41846H114.82L118.471 12.3921L122.122 2.41846H126.254L120.393 17.1718Z" fill="white" />
                        <path d="M150.029 24.9786H146.666V8.2816L142.566 24.9786H139.716L135.616 8.18601V24.9786H132.285V2.41846H136.897L141.157 17.5542L145.417 2.41846H150.029V24.9786Z" fill="white" />
                        <path d="M166.587 20.1351H161.207L159.957 24.9786H156.114L161.463 2.41846H166.427L171.744 24.9786H167.836L166.587 20.1351ZM163.929 7.13445L162.039 16.5026H165.787L163.929 7.13445Z" fill="white" />
                        <path d="M188.174 24.9786L185.227 16.2796H181.832V24.9786H177.893V2.41846H185.067C189.006 2.41846 191.601 4.80835 191.601 8.31339V10.3846C191.601 12.5195 190.64 14.2402 189.006 15.2599L192.305 24.9786H188.174ZM181.832 12.647H185.291C186.861 12.647 187.693 11.8822 187.693 10.5121V8.18601C187.693 6.81579 186.861 6.05109 185.323 6.05109H181.832V12.647Z" fill="white" />
                        <path d="M213.38 29.9749L205.533 25.4245C201.145 25.3607 198.487 22.7798 198.487 19.0515V8.40883C198.487 4.58502 201.209 1.97217 205.725 1.97217C210.241 1.97217 212.964 4.58502 212.964 8.40883V19.0515C212.964 21.1546 212.099 22.9071 210.562 24.0224L213.38 25.6475V29.9749ZM205.725 21.7919C208.095 21.7919 209.024 20.7722 209.024 19.0834V8.34503C209.024 6.62436 208.095 5.60469 205.725 5.60469C203.355 5.60469 202.426 6.62436 202.426 8.34503V19.0834C202.426 20.7722 203.355 21.7919 205.725 21.7919Z" fill="white" />
                    </svg>
            }

        </a>

    );
}

export default PrimaryLogo;
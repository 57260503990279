import ClientTestimonialData from "../../constants/client-testimonial-data";
import ClientTestimonialCard from "./ClientTestimonialCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { useEffect, useState } from "react";

const ClientTestimonialSection = () => {
  //only for mobile
  const [currentActive, setCurrentActive] = useState(0);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const changeTestimonialInterval = setTimeout(() => {
      setAnimate(true);

      setTimeout(() => {
        setCurrentActive((prevIndex) =>
          prevIndex >= ClientTestimonialData.length - 1 ? 0 : ++prevIndex
        );
        setAnimate(false);
      }, 300);
    }, 3000);
    return () => {
      clearTimeout(changeTestimonialInterval);
    };
  }, [currentActive]);

  return (
    <>
      <div id="client-testimonials" className="-translate-y-20" />
      <section className="bg-gradient-to-r from-[#FF911F] to-[#FB6828] h-fit w-full py-8 px-5 lg:px-16">
        <div className="font-bebas-neue font-bold text-5xl w-full text-center capitalize text-white pb-8">
          what our clients say
        </div>
        <div className="hidden lg:block">
          {ClientTestimonialData.length > 3 ? (
            <Swiper
              className="relative flex gap-4 w-full z-0"
              modules={[Navigation]}
              spaceBetween={15}
              slidesPerView={3}
              // onSwiper={(swiper) => console.log(swiper)}
              direction="horizontal"
              navigation={{
                prevEl: "#carousel1-btn-prev-2", // Previous button selector
                nextEl: "#carousel1-btn-next-2", // Next button selector
              }}
              loop
            >
              {ClientTestimonialData.map((cardData, index) => {
                return (
                  <SwiperSlide>
                    <ClientTestimonialCard cardData={cardData} key={index} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          ) : (
            <div className="flex flex-col lg:flex-row gap-8 justify-center items-start ">
              {ClientTestimonialData.map((cardData, index) => {
                return (
                  <ClientTestimonialCard cardData={cardData} key={index} />
                );
              })}
            </div>
          )}
        </div>
        <div className=" lg:hidden flex flex-col lg:flex-row gap-8 justify-center items-center">
          {/* <Swiper
            className="relative flex gap-4 w-full z-0"
            modules={[Navigation]}
            spaceBetween={15}
            slidesPerView={3}
            // onSwiper={(swiper) => console.log(swiper)}
            direction="horizontal"
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              720: {
                slidesPerView: 2,
              },
              1280: {
                slidesPerView: 3,
              },
            }}
            navigation={{
              prevEl: "#carousel1-btn-prev-2", // Previous button selector
              nextEl: "#carousel1-btn-next-2", // Next button selector
            }}
            loop
          > */}
          {/* {ClientTestimonialData.map((cardData, index) => {
              return ( */}
          {/* <SwiperSlide> */}
          <ClientTestimonialCard
            cardData={ClientTestimonialData[currentActive]}
            animate={animate}
            isMobile
            // key={index}
          />
          {/* </SwiperSlide> */}
          {/* ); */}
          {/* })} */}
          {/* </Swiper> */}
        </div>
      </section>
    </>
  );
};

export default ClientTestimonialSection;

import { useState } from "react";
import PrimaryLogo from "./PrimaryLogo";
import SocialLogo from "./SocialLogo";
import { Twirl as Hamburger } from "hamburger-react";
import calendlyLinks from "constants/calendlyLinks";

const NavBar = ({ isSticky }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  return (
    <>
      {isSticky ? (
        <div
          className={`sticky bg-white opacity-95"  px-2 sm:px-5 py-4 lg:py-5 w-full z-50 lg:justify-center top-0 left-0 flex
           flex-row items-center justify-between monitor:px-24`}
          id="nav-bar"
        >
          <PrimaryLogo isDark={true} />

          <div className=" lg:flex flex-row text-sm text-white gap-5 h-full items-center ml-auto mr-10 sm:mr-3 monitor:mr-0 hidden">
            <a
              href="#our-story"
              onClick={() => setIsSidebarOpen(false)}

              className="text-nowrap text-[#333] hover:text-orange-primary"
            >
              Our Story
            </a>
            <div className="h-6 w-[1px] bg-[#333] opacity-60"></div>

            <a
              href="#our-process"
              onClick={() => setIsSidebarOpen(false)}

              className="text-[#333] text-nowrap hover:text-orange-primary"
            >
              Process
            </a>
            <div className="h-6 w-[1px] bg-[#333] opacity-60"></div>

            <a
              href="#case-studies"
              onClick={() => setIsSidebarOpen(false)}

              className="text-[#333] text-nowrap hover:text-orange-primary"
            >
              Case Studies
            </a>
            <div className="h-6 w-[1px] bg-[#333] opacity-60"></div>

            <a
              onClick={() => setIsSidebarOpen(false)}
              href="#people" className="text-[#333] text-nowrap hover:text-orange-primary">
              People
            </a>
            <div className="h-6 w-[1px] bg-[#333] opacity-60"></div>
            <div className="flex flex-row gap-2 h-full justify-center items-center">
              <SocialLogo logoName="linkedin" isDark={true} />
              <SocialLogo logoName="instagram" isDark={true} />
            </div>
            <a
              href={calendlyLinks.georgia}
              className="bg-orange-primary h-10 w-32 rounded-[4px] text-sm text-center flex justify-center items-center border border-orange-primary hover:text-orange-primary hover:bg-transparent"
            >
              Get In Touch
            </a>
          </div>
          <div className="cursor-pointer text-white rounded-full overflow-hidden  transition-all duration-500 lg:hidden">
            <Hamburger
              color={"#333"}
              size={24}
              toggled={isSidebarOpen}
              toggle={setIsSidebarOpen}
            />
          </div>
        </div>
      ) : (
        <div
          className={`${isSidebarOpen ? "bg-white opacity-95 lg:bg-none" : "bg-none"
            }  sticky py-4 lg:top-12 md:w-auto
             lg:py-5 lg:mx-16 z-50 lg:justify-center top-0 left-0 flex flex-row items-center justify-between px-4 lg:px-16 mx-0
              transition-all duration-300 ease-in-out monitor:mx-auto monitor:max-w-[calc(1600px-100px)]`}
          id="nav-bar"
        >
          <PrimaryLogo isDark={isSidebarOpen} />

          <div className=" lg:flex flex-row text-sm text-white gap-5 h-full items-center ml-auto monitor:mr-0 hidden">
            <a
              href="#our-story"
              onClick={() => setIsSidebarOpen(false)}
              className="text-nowrap hover:text-orange-primary"
            >
              Our Story
            </a>
            <div className="h-6 w-[1px] bg-white opacity-60"></div>

            <a
              href="#our-process"
              onClick={() => setIsSidebarOpen(false)}
              className="text-nowrap hover:text-orange-primary"
            >
              Process
            </a>
            <div className="h-6 w-[1px] bg-white opacity-60"></div>

            <a
              href="#case-studies"
              onClick={() => setIsSidebarOpen(false)}
              className="text-nowrap hover:text-orange-primary"
            >
              Case Studies
            </a>
            <div className="h-6 w-[1px] bg-white opacity-60"></div>

            <a
              onClick={() => setIsSidebarOpen(false)}
              href="#people" className="text-nowrap hover:text-orange-primary">
              People
            </a>
            <div className="h-6 w-[1px] bg-white"></div>
            <div className="flex flex-row gap-2 h-full justify-center items-center">
              <SocialLogo logoName="linkedin" />
              <SocialLogo logoName="instagram" />
            </div>
            <a
              href={calendlyLinks.georgia}
              className="bg-orange-primary mx-2 h-10 w-32 rounded-[4px] text-sm text-center flex justify-center items-center border border-orange-primary hover:text-orange-primary hover:bg-transparent"
            >
              Get In Touch
            </a>
          </div>
          <div className="cursor-pointer text-white rounded-full overflow-hidden  transition-all duration-500 lg:hidden">
            <Hamburger
              size={24}
              color={`${isSidebarOpen ? "#333" : "#fff"}`}
              toggled={isSidebarOpen}
              toggle={setIsSidebarOpen}
            />
          </div>
        </div>
      )}

      <div
        className={`w-screen h-fit pb-4 fixed top-[80px] lg:hidden bg-white opacity-95 z-50 transition-all duration-300 ease-in-out ${isSidebarOpen ? "right-0" : "-right-[110%]"
          }`}
      >
        {isSidebarOpen && (
          <div className="px-5">
            <nav className="p-4 flex flex-col gap-6 text-black justify-center items-center mt-6">
              <a
                href="#our-story"
                onClick={() => setIsSidebarOpen(false)}

                className="text-nowrap hover:text-orange-primary"
              >
                Our Story
              </a>
              <a
                href="#our-process"
                onClick={() => setIsSidebarOpen(false)}

                className="text-nowrap hover:text-orange-primary"
              >
                Process
              </a>
              <a
                href="#case-studies"
                onClick={() => setIsSidebarOpen(false)}

                className="text-nowrap hover:text-orange-primary"
              >
                Case Studies
              </a>
              <a
                href="#people"
                onClick={() => setIsSidebarOpen(false)}

                className="text-nowrap hover:text-orange-primary"
              >
                People
              </a>
            </nav>
            <div className="flex flex-row gap-2 my-10 justify-center items-center">
              <SocialLogo logoName="linkedin" isDark={true} />
              <SocialLogo logoName="instagram" isDark={true} />
            </div>
            <a
              href={calendlyLinks.georgia}
              className="bg-orange-primary text-white h-10 w-full max-w-96 mx-auto rounded-[4px] text-sm text-center flex justify-center items-center border border-orange-primary hover:text-orange-primary hover:bg-transparent"
            >
              Get In Touch
            </a>
          </div>
        )}
      </div>
    </>
  );
};

export default NavBar;

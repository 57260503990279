const ClientTestimonialCard = ({ cardData, animate, isMobile }) => {
  return (
    <div className="flex flex-col w-full  lg:w-1/3 md:max-w-[430px]">
      <div
        className="rounded-full p-1 bg-white h-40 w-fit mx-auto -mb-20 z-10 transition-all"
      >
        <img
          src={cardData.imgUrl}
          alt=""
          loading="lazy"
          className={`w-auto h-full rounded-full transition-all ease-in-out duration-300 ${
            isMobile ? (animate ? "opacity-0" : "opacity-1") : "opacity-1"
          }`}
        />
      </div>
      <div class="bg-gradient-to-b from-teal-50 to-teal-200 min-h-[500px] min-w-[300px] max-w-[400px] mx-auto shadow-md px-8 pt-28 pb-10 rounded-2xl border-[6px] border-white">
        <div className="text-2xl font-bold text-center">
          {cardData.name}
        </div>
        <div className="text-center">{cardData.role}</div>
        <div className="text-center">{cardData.roleSubtitle}</div>
        <br />
        <div className="">{cardData.body}</div>
      </div>
    </div>
  );
};

export default ClientTestimonialCard;

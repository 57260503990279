import OurStorySection from "components/homepage/OurStorySection";
import ClientLogosMarquee from "../components/homepage/ClientLogosMarquee";
import ClientTestimonialSection from "../components/homepage/ClientTestimonialSection";
import HeroSection from "../components/homepage/HeroSection";
import OurProcessSection from "components/homepage/OurProcessSection";
import NewsSection from "components/homepage/NewsSection";
import CaseStudies from "components/homepage/CaseStudies";
import PeopleSection from "components/homepage/PeopleSection";
import BehindTheScenesSection from "components/homepage/BehindTheScenesSection";
import Footer from "components/Footer";
import NavBar from "components/NavBar";
import { useEffect, useState } from "react";

export const HomePage = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setIsSticky(scrollY > 40);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSticky]);
  useEffect(() => {
    document.title = "Storymarq - HomePage";
  }, []);
  return (
    <div className={`w-full transition-all ease-in-out duration-500`}>
      <NavBar isSticky={isSticky} />
      <HeroSection />
      <ClientLogosMarquee />
      <div className="lg:mx-[67px] h-fit lg:min-h-[600px] monitor:max-w-monitorWidth monitor:mx-auto w-auto bg-gradient-to-b from-[#ecf4f5] to-[#d3edf0] px-5 py-8 lg:p-16 lg:mb-8 lg:rounded-3xl rounded-br-none flex flex-col justify-center items-center">
        <div className="text-4xl md:text-5xl lg:text-5xl capitalize font-bebas-neue font-bold w-full text-center text-orange-primary">
          aligning with what we believe
        </div>
        <div className="text-lg md:text-xl lg:text-[28px] lg:w-3/4 mx-auto mt-5 leading-8 md:leading-10">
          Partnering with thought leaders, dreamers, and entrepreneurs,
          Storymarq leverages each client’s unique stories to create strategic
          video campaigns that inspire intrigue, engagement, and delight in the
          hearts, minds, and lives of their audiences and communities.
          <br />
          <br />
          Inclusivity and diversity is at the heart of everything we do. We
          strive to create equitable platforms where every story has the
          opportunity to be heard.
        </div>
      </div>
      <ClientTestimonialSection />
      <OurStorySection />
      <OurProcessSection />
      <NewsSection />
      <CaseStudies />
      <PeopleSection />
      <BehindTheScenesSection />
      <Footer />
    </div>
  );
};

const SecondaryLogo = () => {
    return (
        <a href="/">
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 221 39" fill="none">
                <g clip-path="url(#clip0_125_250)">
                    <path d="M18.5365 0.53711L5.67048 0.54329C2.81733 0.544322 0.505249 2.6281 0.505249 5.19806L0.514543 23.9902C0.514543 27.4614 1.44559 29.1981 5.95704 30.4589L23.6945 35.8728L23.7048 5.18776C23.7028 2.6178 21.3896 0.536079 18.5365 0.53711ZM15.2996 25.1881C11.834 26.557 7.92051 24.9327 6.44014 21.5551V21.5531L6.41946 21.5613C6.3791 21.4594 6.33566 21.3594 6.2953 21.2575C3.91595 15.276 6.85601 8.50759 12.8613 6.13849C13.5327 5.87583 14.2154 5.676 14.9003 5.54003C14.834 5.58021 14.7668 5.62347 14.6996 5.67085C13.2296 6.66587 11.5102 8.88768 11.3354 12.0829C14.5671 11.4 17.9168 13.115 19.1727 16.2741C20.5755 19.801 18.8417 23.7924 15.2996 25.1881Z" fill="#FF4D00" />
                    <path d="M38.903 34.6479L38.9032 35.3817C38.9037 36.3089 38.1995 36.9543 37.1954 36.9546L36.5313 36.955L36.5321 38.8094L35.2769 38.81L35.2745 33.0853L37.1856 33.0845C38.1897 33.084 38.9025 33.7288 38.903 34.6479ZM37.6478 34.592C37.6477 34.3663 37.5018 34.2131 37.2751 34.2132L36.5301 34.2136L36.5308 35.8262L37.2677 35.8258C37.5025 35.8257 37.6482 35.6724 37.6481 35.4386L37.6478 34.592Z" fill="white" />
                    <path d="M54.5474 36.7382L53.9482 36.7385L53.949 38.8026L52.6938 38.8031L52.6914 33.0784L54.6025 33.0776C55.6067 33.0772 56.3195 33.7138 56.3199 34.6411L56.3201 35.1732C56.3204 35.6812 56.1019 36.1086 55.7457 36.383L56.4917 38.8015L55.1799 38.8021L54.5474 36.7382ZM53.9476 35.6097L54.6846 35.6093C54.9194 35.6092 55.0651 35.464 55.065 35.2302L55.0647 34.5851C55.0646 34.3514 54.9188 34.2063 54.692 34.2064L53.947 34.2067L53.9476 35.6097Z" fill="white" />
                    <path d="M71.2032 37.2874L71.2021 34.5782C71.2017 33.6188 71.9464 32.9493 73.1125 32.9487C74.2705 32.9483 75.0239 33.6172 75.0243 34.5767L75.0254 37.2859C75.0258 38.2453 74.2812 38.9149 73.1232 38.9154C71.9571 38.9159 71.2037 38.2469 71.2032 37.2874ZM73.7703 37.3025L73.7692 34.5611C73.7691 34.2708 73.5746 34.0774 73.1212 34.0776C72.6515 34.0777 72.4572 34.2714 72.4573 34.5616L72.4584 37.303C72.4586 37.5932 72.6531 37.7867 73.1146 37.7865C73.5762 37.7864 73.7705 37.5927 73.7703 37.3025Z" fill="white" />
                    <path d="M93.5832 34.69L93.5843 37.1573C93.5846 38.1168 92.84 38.7863 91.7468 38.7867L89.8843 38.7875L89.8818 33.0628L91.7363 33.062C92.8295 33.0616 93.5828 33.7306 93.5832 34.69ZM92.3281 34.6744C92.328 34.3842 92.1416 34.1907 91.8258 34.1908L91.1375 34.1911L91.1389 37.6581L91.8192 37.6579C92.1431 37.6577 92.3293 37.4643 92.3292 37.1739L92.3281 34.6744Z" fill="white" />
                    <path d="M108.386 37.2717L108.384 33.0548L109.639 33.0543L109.641 37.2873C109.641 37.5776 109.827 37.771 110.224 37.7708C110.629 37.7707 110.815 37.577 110.815 37.2868L110.813 33.0537L112.068 33.0532L112.07 37.2702C112.07 38.2296 111.35 38.8992 110.233 38.8997C109.115 38.9002 108.386 38.2312 108.386 37.2717Z" fill="white" />
                    <path d="M126.871 37.264L126.87 34.5548C126.87 33.5953 127.614 32.9257 128.764 32.9253C129.914 32.9248 130.668 33.5938 130.668 34.5532L130.668 35.2709L129.397 35.2714L129.397 34.5376C129.397 34.2474 129.186 34.0539 128.773 34.0541C128.344 34.0543 128.125 34.2479 128.125 34.5382L128.126 37.2796C128.127 37.5698 128.345 37.7633 128.766 37.7631C129.188 37.7629 129.398 37.5693 129.398 37.2791L129.398 36.5453L130.669 36.5448L130.669 37.2624C130.67 38.2219 129.925 38.8914 128.775 38.8919C127.625 38.8924 126.872 38.2235 126.871 37.264Z" fill="white" />
                    <path d="M148.846 34.1669L147.656 34.1674L147.658 38.7633L146.403 38.7638L146.401 34.168L145.202 34.1685L145.202 33.0396L148.846 33.0381L148.846 34.1669Z" fill="white" />
                    <path d="M163.485 33.0318L164.74 33.0312L164.743 38.7559L163.488 38.7565L163.485 33.0318Z" fill="white" />
                    <path d="M179.592 37.242L179.591 34.5328C179.591 33.5734 180.335 32.9038 181.502 32.9033C182.66 32.9028 183.413 33.5717 183.413 34.5312L183.414 37.2404C183.415 38.1998 182.67 38.8694 181.512 38.8699C180.346 38.8704 179.593 38.2015 179.592 37.242ZM182.159 37.257L182.158 34.5156C182.158 34.2253 181.964 34.0319 181.51 34.0321C181.04 34.0323 180.846 34.226 180.846 34.5162L180.847 37.2576C180.848 37.5478 181.042 37.7413 181.504 37.7411C181.965 37.7409 182.159 37.5472 182.159 37.257Z" fill="white" />
                    <path d="M201.963 33.0156L201.966 38.7403L200.864 38.7408L199.365 35.4839L199.366 38.7414L198.273 38.7419L198.271 33.0172L199.364 33.0167L200.863 36.2896L200.862 33.0161L201.963 33.0156Z" fill="white" />
                    <path d="M216.79 37.0409L216.79 36.8152L218.045 36.8147L218.046 37.1694C218.046 37.5806 218.37 37.7579 218.718 37.7578C218.993 37.7577 219.301 37.5881 219.301 37.2011C219.3 36.1771 216.895 36.42 216.895 34.5656C216.894 33.59 217.525 32.8882 218.692 32.8877C219.841 32.8873 220.481 33.524 220.482 34.4915L220.482 34.8464L219.227 34.8469L219.227 34.4114C219.227 34.1615 218.984 34.0164 218.684 34.0165C218.401 34.0167 218.158 34.17 218.158 34.5006C218.158 35.323 220.563 35.1365 220.564 37.0877C220.564 38.1359 219.868 38.8539 218.694 38.8543C217.52 38.8548 216.791 38.1294 216.79 37.0409Z" fill="white" />
                    <path d="M33.1614 19.1071V18.1823H37.2419V19.5695C37.2419 21.5515 38.6684 22.5424 40.659 22.5424C42.5167 22.5424 43.9099 21.5184 43.9099 19.6356C43.9099 14.6809 33.6259 16.0021 33.6259 8.47074C33.6259 4.60605 36.1139 1.89746 40.5925 1.89746C45.1044 1.89746 47.6588 4.44093 47.6588 8.27257V9.65992H43.5783V7.9753C43.5783 6.58794 42.3841 5.66307 40.5925 5.66307C38.8674 5.66307 37.7064 6.58794 37.7064 8.23961C37.7064 12.4346 47.9905 11.2455 47.9905 19.2393C48.0237 23.3683 45.2702 26.209 40.5925 26.209C35.9813 26.209 33.1614 23.4013 33.1614 19.1071Z" fill="white" />
                    <path d="M68.0614 6.12559H62.687V25.7466H58.6067V6.12559H53.2323V2.35986H68.0614V6.12559Z" fill="white" />
                    <path d="M73.3701 19.6026V8.56995C73.3701 4.60604 76.19 1.89746 80.8676 1.89746C85.5453 1.89746 88.3652 4.60604 88.3652 8.56995V19.6026C88.3652 23.5334 85.5453 26.209 80.8676 26.209C76.19 26.209 73.3701 23.5334 73.3701 19.6026ZM84.2847 19.6356V8.50381C84.2847 6.72009 83.3227 5.66307 80.8676 5.66307C78.4128 5.66307 77.4506 6.72009 77.4506 8.50381V19.6356C77.4506 21.3863 78.4128 22.4433 80.8676 22.4433C83.3227 22.4433 84.2847 21.3863 84.2847 19.6356Z" fill="white" />
                    <path d="M105.612 25.7466L102.56 16.7288H99.0435V25.7466H94.9629V2.35986H102.394C106.474 2.35986 109.162 4.83732 109.162 8.47077V10.6179C109.162 12.831 108.167 14.6147 106.474 15.6718L109.891 25.7466H105.612ZM99.0435 12.9632H102.626C104.252 12.9632 105.114 12.1704 105.114 10.75V8.33873C105.114 6.9183 104.252 6.12559 102.659 6.12559H99.0435V12.9632Z" fill="white" />
                    <path d="M124.688 17.6538V25.7466H120.607V17.5876L114.569 2.35986H118.915L122.697 12.6989L126.479 2.35986H130.759L124.688 17.6538Z" fill="white" />
                    <path d="M155.385 25.7466H151.902V8.43781L147.655 25.7466H144.702L140.456 8.33873V25.7466H137.006V2.35986H141.783L146.195 18.0501L150.608 2.35986H155.385V25.7466Z" fill="white" />
                    <path d="M172.537 20.7256H166.963L165.669 25.7466H161.688L167.229 2.35986H172.371L177.878 25.7466H173.83L172.537 20.7256ZM169.783 7.24863L167.826 16.96H171.707L169.783 7.24863Z" fill="white" />
                    <path d="M194.896 25.7466L191.844 16.7288H188.328V25.7466H184.247V2.35986H191.678C195.759 2.35986 198.446 4.83732 198.446 8.47077V10.6179C198.446 12.831 197.451 14.6147 195.759 15.6718L199.176 25.7466H194.896ZM188.328 12.9632H191.911C193.536 12.9632 194.399 12.1704 194.399 10.75V8.33873C194.399 6.9183 193.536 6.12559 191.944 6.12559H188.328V12.9632Z" fill="white" />
                    <path d="M221.005 30.9261L212.877 26.209C208.332 26.1429 205.579 23.4674 205.579 19.6026V8.56995C205.579 4.60604 208.399 1.89746 213.076 1.89746C217.754 1.89746 220.574 4.60604 220.574 8.56995V19.6026C220.574 21.7826 219.678 23.5994 218.086 24.7555L221.005 26.4402V30.9261ZM213.076 22.4433C215.532 22.4433 216.493 21.3863 216.493 19.6356V8.50381C216.493 6.72009 215.532 5.66307 213.076 5.66307C210.622 5.66307 209.659 6.72009 209.659 8.50381V19.6356C209.659 21.3863 210.622 22.4433 213.076 22.4433Z" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_125_250">
                        <rect width="220.5" height="38.3782" fill="white" transform="translate(0.505249 0.537109)" />
                    </clipPath>
                </defs>
            </svg>
        </a>

    );
}

export default SecondaryLogo;
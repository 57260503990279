const MarqueeLogoLinks = [
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/65591944403c557189d146b4_Adweek.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/6559194306244ca683a8f66d_Amazon.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/655919434b526a2961d47c17_American_Red_Cross.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/65591944c192fd9129529255_atlantic-records-seeklogo.com.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/655919439bdbbe5f0925d5ef_BBB.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/65591943cb1ae6c081cd4dd2_Bleacher_Report.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/65591943521595917daa1cd2_Blue_cross_blue_sheild.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/6559194436c599db0bd458fa_Bowlero.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/655919430ef35b5c924ef03e_Campbells.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/65591943f4d3d4dd5618dbf7_Carnegie_Hall.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/65591944aced22f21dc5917c_conde_nast.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/65591943bdf1f4819c1d04f2_CPJ.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/655919443bccc1e0f91fd4c9_Crown_Royal.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/65591944aced22f21dc5917f_Disney.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/65591943d628c3fb64118bf2_FCC.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/6559194491ac8fe07318d1f8_Fitch.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/65591943403c557189d1469c_Fortune.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/655919431f4650d9c535cd3d_Glamour.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/6559194331cdcff15aaa720c_Good-housekeeping-seeklogo.com.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/655919439e315820aac58d16_Google.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/655919439e315820aac58cf6_HSBC.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/65591943378415d1db0e0938_Huffpost.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/65591943a2e6e1a3ab8efce4_Hulu.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/65591944c192fd9129529264_Hypebeast%20Hong%20Kong%20Ltd.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/65591943840455618cc0596e_IBM.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/65593a54cb1ae6c081e25589_jw-player-seeklogo.com.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/65591944840455618cc059b7_Kidz_Bop.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/65591944de12de54c5e5f602_Linked_In.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/65591944e6f2630e0e4fd450_Medidata-seeklogo.com.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/6559194495abd34586b5dcf0_Meta-new-facebook-2021-seeklogo.com-1.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/65591943557a0a7fd68b23f3_MetLife.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/6559194406244ca683a8f6d5_Microsoft.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/65591944e6f2630e0e4fd42f_Moto.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/655919457c9a1ab15314eda9_MSK.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/6559194487a5a72a407ce2a2_MTA.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/65591944f4d3d4dd5618dc52_nbc-universal-seeklogo.com.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/655919443da2edc4a0016fd6_Netflix.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/65591944309c6b9c06b84083_Now_This.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/655919457c9a1ab15314eda4_NYC_DeptofEd.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/65591944403c557189d1470f_Playboy.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/65591944ceb72f12e7731593_PEI.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/65591944c192fd91295292c9_Salesforce.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/65591944038e8e69b71c04c6_Samsung.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/65591944309c6b9c06b83fe1_Sundance.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/65592e5cb1779eb4cac2361f_TNCLogoPrimary_CMYK_Nature_Conservancy.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/655919444cb61f6f2b7427ab_Tribeca.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/6559194431cdcff15aaa72bd_udemy-seeklogo.com-3.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/6559194406244ca683a8f6d0_Walmart.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/65591944bdf1f4819c1d055e_Warner_Music.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/6559194531cdcff15aaa7311_Well_and_Good.svg",
  "https://assets-global.website-files.com/655909644cb61f6f2b69f3cf/65591944840455618cc059df_Zipcar.svg",
];

export default MarqueeLogoLinks;

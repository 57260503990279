import { useState } from "react";

const OurStorySection = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <div id="our-story" className=" -translate-y-20" />
      <section className=" md:mx-16 md:my-8 w-auto lg:rounded-3xl overflow-hidden relative monitor:max-w-monitorWidth monitor:mx-auto">
        <div className="bg-[url('assets/homepage/our_story_background.jpg')] h-60 md:h-96 bg-cover  bg-right relative flex justify-center bg-no-repeat">
          <div class="absolute inset-0 bg-black opacity-40 z-0"></div>

          <div className="z-10 relative flex flex-col justify-center items-center gap-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-[64px] h-[64px] md:w-[156px] md:h-[151px]"
              viewBox="0 0 156 151"
              fill="none"
            >
              <g opacity="0.5" clip-path="url(#clip0_277_62)">
                <g id="uuid-6e6d1f19-f1dc-4013-9a9c-43daecc927ca">
                  <path
                    id="Vector"
                    d="M94.7892 73.1772C98.6738 82.6816 93.8695 93.4392 84.063 97.201C74.4666 100.888 63.6365 96.5052 59.5357 87.4021V87.3982L59.4786 87.4219C59.3684 87.1451 59.2482 86.8743 59.134 86.5975C52.5515 70.475 60.6959 52.2352 77.3235 45.8522C79.1832 45.1445 81.0716 44.6068 82.966 44.2391C82.7845 44.3498 82.5989 44.4664 82.4113 44.593C78.3451 47.2735 73.5816 53.2592 73.0963 61.872C82.0422 60.0336 91.3144 64.6573 94.7892 73.1772Z"
                    fill="white"
                  />
                  <path
                    id="Vector_2"
                    d="M77.9883 0.755859C35.2592 0.755859 0.621582 34.3354 0.621582 75.7549C0.621582 117.174 35.2592 150.756 77.9883 150.756C120.717 150.756 155.357 117.176 155.357 75.7549C155.357 34.3335 120.717 0.755859 77.9883 0.755859ZM108.111 125.143L58.6997 111.116C46.9682 108 44.3968 103.319 44.3927 93.963L44.3825 43.31C44.3805 36.3834 50.7835 30.7693 58.6834 30.7654L94.2997 30.7595C102.202 30.7574 108.605 36.3695 108.609 43.2962L108.111 125.143Z"
                    fill="white"
                  />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_277_62">
                  <rect
                    width="154.736"
                    height="150"
                    fill="white"
                    transform="translate(0.621582 0.755859)"
                  />
                </clipPath>
              </defs>
            </svg>
            <div className="text-5xl font-bold md:text-5xl font-bebas-neue text-white">
              Our Story
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-b from-[#ecf4f5] to-[#d0ecef] h-full flex flex-col items-center  px-5 py-8">
          <div className="max-w-[600px]">
            <div className="text-lg mb-6">
              In 2014, after 20 years working in creative disciplines ranging from
              design, to audio visual presentation, brand strategy, film and video
              production, Niger Miles founded Mark by Fire, a comprehensive
              branding strategy company. As his business grew, he found himself
              drawn to work with social impact organizations, finding fulfillment
              in working with projects aligned with his personal ethics and ethos.
              <br />
              <br />
              However, he recognized that clients often came to him for simple
              projects without recognizing the value of a larger, more strategic
              approach to reaching their long term goals of engaging with their
              audience and growing their brand.
            </div>
            <div
              className={`text-lg mb-6 transition-all duration-500 ease-in-out ${isExpanded
                ? "lg:max-h-[1000px] opacity-100"
                : "max-h-0 opacity-0 overflow-hidden"
                }`}
            >
              In 2023, Niger and his wife Georgia M. Hill founded Storymarq
              Productions, a video production strategy company bridging the gap
              between branding and video strategy, which all too often are left up
              to disaggregated agencies and production companies. They recognize
              the power of storytelling to connect people with people, and the
              impact of strategy to address and achieve longterm goals.
              <br />
              <br />
              The two partners find their strength as a team from the diversity of
              their experiences and skills.
              <br />
              <br />
              Before launching her career in production, Georgia worked as a
              psychotherapist and is fascinated by the complexity of the human
              experience.  Her unique, curious nature helps to tap into each
              client’s unique story and “wow factor”, all the while managing the
              macro and micro needs of each project from conception to completion.
              A lifelong student and practitioner of the arts from visual art to
              music and performance, she also brings her creative mind, melding
              the day-to-day business necessities with a deeper understanding of
              artistry and intuitive design.
              <br />
              <br />
              Niger is the creative powerhouse with his highly technical and
              mastered skill set, from creative direction, comprehensive branding
              and design, and the full range of video creation, from filming,
              lighting and directing to all stages of post production and
              finishing.

              <br />
              <br />
            </div>

            <div
              className="mb-7 flex flex-row gap-3 h-fit mx-auto w-fit z-10 cursor-pointer"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <div>
                Show&nbsp;
                {isExpanded ? "less" : "more"}
              </div>
              <svg
                className={`transition-all duration-300 w-3 h-auto mt-1 ${isExpanded ? "-rotate-180" : ""
                  }`}
                xmlns="http://www.w3.org/2000/svg"
                fill="#000000"
                height="800px"
                width="800px"
                version="1.1"
                viewBox="0 0 330 330"
              >
                <path d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393  c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393  s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
              </svg>
            </div>
            <div className="w-full flex flex-col lg:flex-row lg:gap-7 justify-center gap-4 ">
              <a
                href="https://www.nigermiles.com/"
                className="text-nowrap bg-orange-primary w-full  py-2 px-2 text-center text-white uppercase rounded-md border border-orange-primary hover:bg-white hover:text-orange-primary"
              >
                see niger's past work
              </a>
              <a
                href="http://www.georgiamhill.com/"
                className="text-nowrap bg-orange-primary w-full  py-2 px-2 text-center text-white uppercase rounded-md border border-orange-primary hover:bg-white hover:text-orange-primary"
              >
                see georgia's past work
              </a>
            </div>

          </div>
        </div>
      </section>
    </>
  );
};

export default OurStorySection;

const BehindTheScenesSection = () => {
  return (
    <>
      <div id="behind-the-scenes" className="-translate-y-20" />
      <section className="lg:mx-16 lg:mb-8 w-auto px-5 lg:px-5 pt-8 lg:pb-0 pb-8 rounded-2xl monitor:max-w-monitorWidth monitor:mx-auto monitor:px-0">
        <div className="uppercase font-bold font-bebas-neue text-center text-4xl lg:text-5xl text-black mb-4 lg:mb-7">
          behind the scenes
        </div>
        {/* for mobile */}
        <div className="lg:gap-4 gap-2 grid grid-cols-3 md:hidden">
          <div className="col-span-3">
            <img
              src={require("assets/homepage/behind_the_scenes/2.jpg")}
              alt="Behind The Scenes 2"
              height="100%"
              width="100%"
              className="object-cover w-full h-full"
              loading="lazy"
            />
          </div>
          <div className="col-span-full grid grid-cols-2 lg:gap-4 gap-2">
            <div>
              <img
                src={require("assets/homepage/behind_the_scenes/3.jpg")}
                alt="Behind The Scenes 3"
                height="100%"
                width="100%"
                className="object-cover h-full w-full"
                loading="lazy"

              />
            </div>
            <div>
              <img
                src={require("assets/homepage/behind_the_scenes/4.jpg")}
                alt="Behind The Scenes 4"
                height="100%"
                width="100%"
                className="object-cover h-full w-full"
                loading="lazy"

              />
            </div>
          </div>
          <div>
            <img
              src={require("assets/homepage/behind_the_scenes/1.jpg")}
              alt="Behind The Scenes 1"
              height="100%"
              width="100%"
              className="object-cover h-full w-full"
              loading="lazy"

            />
          </div>
          <div className="flex flex-col lg:gap-4 gap-2">
            <div className="w-full h-full">
              <img
                src={require("assets/homepage/behind_the_scenes/5.jpg")}
                alt="Behind The Scenes 5"
                height="100%"
                width="100%"
                className="object-cover w-full h-full"
                loading="lazy"

              />
            </div>
            <div className="w-full ">
              <img
                src={require("assets/homepage/behind_the_scenes/7.jpg")}
                alt="Behind The Scenes 7"
                height="100%"
                width="100%"
                className="object-cover w-full h-full"
                loading="lazy"

              />
            </div>
          </div>
          <div className="flex flex-col lg:gap-4 gap-2">
            <div className="w-full ">
              <img
                src={require("assets/homepage/behind_the_scenes/6.jpg")}
                alt="Behind The Scenes 6"
                height="100%"
                width="100%"
                className="object-cover w-full h-full"
                loading="lazy"

              />
            </div>

            <div className="w-full ">
              <img
                src={require("assets/homepage/behind_the_scenes/8.jpg")}
                alt="Behind The Scenes 8"
                height="100%"
                width="100%"
                className="object-cover w-full h-full"
                loading="lazy"

              />
            </div>
          </div>

          <div className="col-span-3 flex flex-col gap-2 ">
            <div className="flex flex-row lg:gap-4 gap-2 h-1/2">
              <div className="w-full">
                <img
                  src={require("assets/homepage/behind_the_scenes/9.jpg")}
                  alt="Behind The Scenes 5"
                  height="100%"
                  width="100%"
                  className="object-cover w-full h-full"
                  loading="lazy"

                />
              </div>
              <div className="w-full">
                <img
                  src={require("assets/homepage/behind_the_scenes/10.jpg")}
                  alt="Behind The Scenes 7"
                  height="100%"
                  width="100%"
                  className="object-cover w-full h-full"
                  loading="lazy"

                />
              </div>
            </div>
            <div className="flex flex-row lg:gap-4 gap-2 h-1/2">
              <div className="w-full h-full">
                <img
                  src={require("assets/homepage/behind_the_scenes/11.jpg")}
                  alt="Behind The Scenes 6"
                  height="100%"
                  width="100%"
                  className="object-cover w-full h-full"
                  loading="lazy"

                />
              </div>

              <div className="w-full h-full">
                <img
                  src={require("assets/homepage/behind_the_scenes/12.jpg")}
                  alt="Behind The Scenes 8"
                  height="100%"
                  width="100%"
                  className="object-cover w-full h-full"
                  loading="lazy"

                />
              </div>
            </div>
          </div>

        </div>

        {/* for desktop */}
        <div className="hidden md:grid grid-cols-1 lg:grid-cols-5 gap-3 overflow-hidden w-full mb-3">
          <div className="lg:col-span-2 w-full h-full relative overflow-hidden">
            <img
              src={require("assets/homepage/behind_the_scenes/1.jpg")}
              alt="Behind The Scenes 1"
              height="100%"
              width="100%"
              className="object-cover absolute w-full h-full"
              loading="lazy"

            />
          </div>
          <div className="lg:col-span-3">
            <div className="flex flex-col w-full h-auto gap-3">
              <div className="w-full h-[630px] ">
                <img
                  src={require("assets/homepage/behind_the_scenes/2.jpg")}
                  alt="Behind The Scenes 2"
                  height="100%"
                  width="100%"
                  className="object-cover w-full h-full"
                  loading="lazy"

                />
              </div>
              <div className="flex flex-row gap-3">
                <img
                  src={require("assets/homepage/behind_the_scenes/3.jpg")}
                  alt="Behind The Scenes 3"
                  height="100%"
                  width="100%"
                  className="object-cover h-72 w-full"
                  loading="lazy"

                />
                <img
                  src={require("assets/homepage/behind_the_scenes/4.jpg")}
                  alt="Behind The Scenes 4"
                  height="100%"
                  width="100%"
                  className="object-cover h-72 w-full"
                  loading="lazy"

                />
              </div>
            </div>
          </div>
        </div>

        <div className="hidden md:grid grid-cols-1 md:grid-cols-4 gap-3">
          <div className="w-full h-60 ">
            <img
              src={require("assets/homepage/behind_the_scenes/5.jpg")}
              alt="Behind The Scenes 5"
              height="100%"
              width="100%"
              className="object-cover w-full h-full"
              loading="lazy"

            />
          </div>
          <div className="w-full h-60 ">
            <img
              src={require("assets/homepage/behind_the_scenes/6.jpg")}
              alt="Behind The Scenes 6"
              height="100%"
              width="100%"
              className="object-cover w-full h-full"
              loading="lazy"

            />
          </div>
          <div className="w-full h-60 ">
            <img
              src={require("assets/homepage/behind_the_scenes/7.jpg")}
              alt="Behind The Scenes 7"
              height="100%"
              width="100%"
              className="object-cover w-full h-full"
              loading="lazy"

            />
          </div>
          <div className="w-full h-60 ">
            <img
              src={require("assets/homepage/behind_the_scenes/8.jpg")}
              alt="Behind The Scenes 8"
              height="100%"
              width="100%"
              className="object-cover w-full h-full"
              loading="lazy"

            />
          </div>
        </div>


        <div className="hidden md:grid grid-cols-1 md:grid-cols-4 gap-3 mt-3">
          <div className="w-full h-60 ">
            <img
              src={require("assets/homepage/behind_the_scenes/9.jpg")}
              alt="Behind The Scenes 5"
              height="100%"
              width="100%"
              className="object-cover w-full h-full"
              loading="lazy"

            />
          </div>
          <div className="w-full h-60 ">
            <img
              src={require("assets/homepage/behind_the_scenes/10.jpg")}
              alt="Behind The Scenes 6"
              height="100%"
              width="100%"
              className="object-cover w-full h-full"
              loading="lazy"

            />
          </div>
          <div className="w-full h-60 ">
            <img
              src={require("assets/homepage/behind_the_scenes/11.jpg")}
              alt="Behind The Scenes 7"
              height="100%"
              width="100%"
              className="object-cover w-full h-full"
              loading="lazy"

            />
          </div>
          <div className="w-full h-60 ">
            <img
              src={require("assets/homepage/behind_the_scenes/12.jpg")}
              alt="Behind The Scenes 8"
              height="100%"
              width="100%"
              className="object-cover w-full h-full"
              loading="lazy"

            />
          </div>

        </div>
      </section>
    </>
  );
};

export default BehindTheScenesSection;

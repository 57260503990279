const PeopleSection = () => {
  return (
    <>
      <div id="people" className="-translate-y-20" />
      <section className="h-fit w-full px-5 xl:px-16 py-8 bg-gradient-to-r from-[#FF911F] to-[#FB6828] ">
        <div className="flex flex-col items-center monitor:max-w-monitorWidth monitor:mx-auto">
          <div className="uppercase font-bold font-bebas-neue text-center text-5xl text-white mb-16">
            people
          </div>
          <div className="grid grid-cols-2 gap-x-4 gap-y-10 xl:hidden">
            <ProfileCard
              name="niger miles"
              nameHasMobileLineBreak={false}
              designation="executive director"
              src={require("assets/homepage/people/1.jpg")}
            />
            <ProfileCard
              name="Georgia M. Hill"
              nameHasMobileLineBreak={false}
              designation="executive producer"
              src={require("assets/homepage/people/2.jpg")}
            />
            <ProfileCard
              name="Jesse Wakeman"
              designation="Director of Photography"
              src={require("assets/homepage/people/3.jpg")}
            />
            <ProfileCard
              name="Jack Mannion"
              designation="Director of Photography"
              src={require("assets/homepage/people/4.jpg")}
            />
            <ProfileCard
              name="Maduma Mihindukulasuriya"
              designation="1st Assistant Camera"
              src={require("assets/homepage/people/5.jpg")}
            />
            <ProfileCard
              name="Noelle Ramsey"
              designation="PA, Grip, Swing"
              src={require("assets/homepage/people/6.jpg")}
            />
          </div>


          <div className="w-max h-auto xl:flex flex-row flex-wrap justify-center items-center gap-9 mb-16 hidden">
            <ProfileCard
              name="niger miles"
              designation="executive director"
              src={require("assets/homepage/people/1.jpg")}
            />
            <ProfileCard
              name="Georgia M. Hill"
              designation="executive producer"
              src={require("assets/homepage/people/2.jpg")}
            />
          </div>
          <div className="w-max h-auto hidden xl:flex flex-row flex-wrap justify-center items-center gap-9">
            <ProfileCard
              name="Jesse Wakeman"
              designation="Director of Photography"
              src={require("assets/homepage/people/3.jpg")}
            />
            <ProfileCard
              name="Jack Mannion"
              designation="Director of Photography"
              src={require("assets/homepage/people/4.jpg")}
            />
            <ProfileCard
              name="Maduma Mihindukulasuriya"
              designation="1st Assistant Camera"
              src={require("assets/homepage/people/5.jpg")}
            />
            <ProfileCard
              name="Noelle Ramsey"
              designation="PA, Grip, Swing"
              src={require("assets/homepage/people/6.jpg")}
            />
          </div>
        </div>

      </section>
    </>
  );
};

export default PeopleSection;

const ProfileCard = ({ src, name, designation, nameHasMobileLineBreak = true }) => (
  <div className="w-fit flex flex-col items-center  mx-auto">
    <div className="h-auto p-1 w-28 xl:w-32 bg-white rounded-full overflow-hidden">
      <img className="w-full h-full bg-contain rounded-full" src={src} alt="" loading="lazy" />
    </div>
    <div className="text-center w-full capitalize text-white mt-5 font-bold text-xl xl:text-2xl">
      <div className=" w-fit text-center mx-auto">
        <div className="sm:hidden block">
          {(() => {
            const nameParts = name.split(' ');
            return (nameParts.length > 1 && nameHasMobileLineBreak)
              ? [nameParts[0], <br key="break" />, ...nameParts.slice(1)]
              : name;
          })()}
        </div>
        <div className="hidden sm:block">
          {name}
        </div>

      </div>
    </div>
    <div className={`text-center capitalize text-white font-light text-xl xl:text-2xl ${name.includes("niger") ? "px-[6px] custom1:pr-0" : ""}`}>
      {designation}
    </div>
  </div>
);

import OurProcessData from "constants/our-process-data";

const OurProcessSection = () => {
  return (
    <>
      <div id="our-process" className="-translate-y-20" />
      <section class="bg-gradient-to-b from-[#ecf4f5] via-[#d9d9d9] to-[#d9d9d9] py-8 px-5 lg:px-28">
        <div className="uppercase font-bold font-bebas-neue text-center text-5xl text-orange-primary">
          our process
        </div>
        <div className="mt-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 lg:px-0 monitor:max-w-monitorWidth monitor:mx-auto">
          {OurProcessData.map((item, index) => {
            return (
              <div key={index} className="">
                <div className="flex flex-row lg:flex-col gap-4 lg:gap-0 items-center lg:items-start">
                  <div dangerouslySetInnerHTML={{ __html: item.svg }}></div>
                  <div>
                    <div className="capitalize leading-tight [word-spacing:500px] md:[word-spacing:normal] lg:w-1 text-2xl text-orange-primary lg:mt-14 font-bold text-wrap">
                      {item.title}
                    </div>
                    <div className="text-xl mt-1">{item.subtitle}</div>
                  </div>
                </div>
                <div className="md:text-lg mt-7">
                  {item.body}
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default OurProcessSection;

// import profile1 from "../assets/homepage/testimonials/Sheila_1.jpg"

const ClientTestimonialData = [
  {
    imgUrl: require("../assets/homepage/testimonials/shazdeh-omari.jpg"),
    name: "Shazdeh Omari",
    role: "Executive Director of Development",
    roleSubtitle:
      "Craig Newmark Graduate School of Journalism City University of New York",
    body: "Niger and Georgia are two of the hardest working people I know. They are fast, efficient, and decisive. and they know their craft almost better than anyone I've met in the industry. For nearly a decade I have relied on them to produce beautiful, memorable and impactful work and they have never let me down. I wholeheartedly recommend them. ",
  },
  {
    imgUrl: require("../assets/homepage/testimonials/Jeanne_Headshot_1.jpg"),
    name: "Jeanne Dupont",
    role: "Executive Director",
    roleSubtitle: "Rockaway Initiative for Sustainability and Equity",
    body: "Storymarq Productions did an incredible job of creating our video for the UIA 2023 World Congress of Architects in Copenhagen. The film was well received by the community. Niger & Georgia captured footage with students and professional partners to build a compelling narrative. We look to forward to working with Storymarq as the project continues.",
  },
  {
    imgUrl: require("../assets/homepage/testimonials/Sheila_1.jpg"),
    name: "Sheila Webb Halpern",
    role: "NY Director of Policy",
    roleSubtitle: "for Strategic Communications The Nature Conservancy",
    body: "STORYMARQ Productions created a video that helped us illustrate the importance of grant funding and bringing community voices into conversations about how to plan for the future. The film also helped start important discussions with internal and external audiences. When challenges arose during the filming process, Niger and Georgia still delivered a strong final product. ",
  },
];

export default ClientTestimonialData;

import heroBackgroundVideo from "assets/homepage/hero-video.mp4";
import calendlyLinks from "constants/calendlyLinks";
const HeroSection = () => {
  return (
    <div className="h-[calc(244px+80px+64px)] lg:h-[630px] monitor:max-w-monitorWidth monitor:mx-auto w-auto lg:mx-[67px] -mt-20 lg:-mt-[40px] lg:rounded-3xl overflow-hidden lg:rounded-br-none relative flex flex-col items-center justify-center z-0">
      <div className="bg-black bg-opacity-30 w-full h-full absolute top-0 z-10"></div>
      <video
        loop
        autoPlay
        muted
        playsInline
        poster={require("assets/homepage/hero-video-poster.jpg")}
        preload="metadata"
        className="w-full h-full object-cover lg:rounded-3xl rounded-br-none relative z-0"
      >
        <source src={heroBackgroundVideo}></source>
        Your browser does not support the video tag.
      </video>

      <div className="absolute bottom-[40px] left-0 pl-4 lg:pl-16 flex flex-col lg:items-start z-[10] w-full pr-4 ">
        <h1 className="font-bebas-neue font-bold capitalize text-white text-5xl lg:text-8xl ">
          gain <span className="text-orange-primary">clarity</span>
          <br />
          on your <span className="text-orange-primary">video</span>
          <br />
          messaging <span className="text-orange-primary">campaign</span>
        </h1>
        <div className="flex flex-row flex-nowrap lg:gap-5 gap-3 w-full mt-5 max-w-[450px]">
          <a
            href="#case-studies"
            className="bg-orange-primary text-white h-10 lg:w-44 w-full rounded-[4px] text-sm text-center flex justify-center items-center border border-orange-primary hover:text-orange-primary hover:bg-transparent"
          >
            Case Studies
          </a>
          <a
            href={calendlyLinks.georgia}
            className="border text-white h-10 w-full lg:w-44 rounded-[4px] text-sm text-center flex justify-center items-center hover:border-orange-primary hover:text-orange-primary"
          >
            Get In Touch
          </a>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;

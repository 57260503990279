import { useState } from "react";

const CaseStudyCard = ({ cardData }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const _renderTextBlockBody = (textBlock) => {
    let text;
    switch (textBlock.type) {
      case "ul":
        text = (
          <ul className="list-disc list-inside">
            {textBlock.body.map((text) => (
              <li>{text}</li>
            ))}
          </ul>
        );
        break;
      case "normal":
      default:
        text = textBlock.body;
        break;
    }
    return text;
  };

  return (
    <div href="/" className="w-full">
      <div className="relative overflow-hidden lg:rounded-t-2xl flex flex-col items-center justify-center ">
        <video
          className="h-60 lg:h-full lg:max-h-[300px] w-full object-cover object-top"
          src={cardData.bgVideo.link}
          alt={cardData.bgVideo.alt}
          poster={cardData.bgVideo.poster}
          loop
          autoPlay
          muted
          playsInline
        />
        <div className="absolute h-full w-full bg-black bg-opacity-30"></div>
        <div className="absolute lg:h-28 flex flex-col lg:flex-row items-center lg:gap-8 gap-3 w-max">
          <img
            src={cardData.bgVideo.logo}
            alt="case_study"
            width="100%"
            height="100%"
            className="max-h-16 w-auto sm:max-h-none contain z-30"
          />
          {/* <div className="bg-white w-0.5 h-full hidden lg:block" />
          <div className="font-bebas-neue font-bold text-white uppercase text-3xl md:text-5xl w-max">
            Case study
          </div> */}
        </div>
        <div
          className="flex flex-row gap-3 h-fit z-10 cursor-pointer absolute text-xs lg:text-lg bottom-3 w-full items-center justify-center text-white opacity-85"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <div>
            Show&nbsp;
            {isExpanded ? "less" : "more"}
          </div>
          <svg
            className={`transition-all duration-300 w-4 h-auto mt-1 ${isExpanded ? "-rotate-180" : ""
              }`}
            xmlns="http://www.w3.org/2000/svg"
            fill="white"
            height="800px"
            width="800px"
            version="1.1"
            viewBox="0 0 330 330"
          >
            <path d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393  c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393  s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
          </svg>
        </div>
      </div>

      <div
        className={`bg-[#ECF4F5] text-[17px] xl:justify-between flex flex-col-reverse lg:flex-row gap-4 lg:gap-10 rounded-2xl overflow-hidden rounded-br-none transition-all duration-500 ease-in-out px-4 lg:px-10  ${isExpanded
          ? "max-h-[3000px] opacity-100 px-5 lg:py-10 py-5"
          : "p-0 max-h-0 opacity-0 overflow-hidden"
          }`}
      >
        <div className="lg:max-w-[45%] w-full">
          {cardData.heading && (
            <h3 className="font-bebas-neue font-bold text-[#333333] text-3xl sm:text-5xl ">
              {cardData.heading}:
            </h3>
          )}
          {cardData.subHeading && (
            <div className="text-3xl sm:text-5xl font-bebas-neue text-[#333333] font-light mb-4 lg:mb-10">
              {cardData.subHeading}
            </div>
          )}
          {cardData.textBlocks.map((block) => {
            return (
              <div className="flex flex-col w-full">
                <div className="font-bold text-xl sm:text-2xl">{block.title}</div>
                <div>{_renderTextBlockBody(block)}</div>
                <br />
              </div>
            );
          })}
        </div>
        <div className="flex flex-col gap-2 sm:gap-4">
          <div className="w-full ">
            <video
              className="w-full h-auto"
              src={cardData.secondaryVideo.link}
              loop
              controls
              playsInline
            />
          </div>
          {
            cardData.gridType === 3 ?
              (
                <div className="flex flex-row h-full gap-2 sm:gap-4">
                  <img
                    className="h-full min-w-[41%] object-cover"
                    src={cardData.verticalImage.link}
                    alt={cardData.verticalImage.alt}
                  />
                  <div className="flex flex-col gap-2 sm:gap-4 w-full  overflow-hidden">
                    <img
                      className="h-full w-full object-cover"
                      src={cardData.horizontalImages[0].link}
                      alt={cardData.horizontalImages[0].alt}
                    />
                    <img
                      className="h-full w-full object-cover"
                      src={cardData.horizontalImages[1].link}
                      alt={cardData.horizontalImages[1].alt}
                    />
                  </div>
                </div>
              ) :
              (
                <div className="flex flex-row h-full gap-3 sm:gap-4">
                  <div className="w-full flex flex-col gap-3 overflow-hidden">
                    <div className="w-full gap-3 flex flex-row">
                      <img
                        className="w-3/5 object-cover"
                        src={cardData.horizontalImages[0].link}
                        alt={cardData.horizontalImages[0].alt}
                      />
                      <img
                        className="w-2/5 object-cover"
                        src={cardData.horizontalImages[1].link}
                        alt={cardData.horizontalImages[1].alt}
                      />
                    </div>
                    <div className="w-full gap-3 flex flex-row">
                      <img
                        className="w-2/5 object-cover"
                        src={cardData.horizontalImages[2].link}
                        alt={cardData.horizontalImages[2].alt}
                      />
                      <img
                        className="w-3/5 object-cover"
                        src={cardData.horizontalImages[3].link}
                        alt={cardData.horizontalImages[3].alt}
                      />
                    </div>
                  </div>
                </div>
              )
          }


        </div>
      </div>
    </div>
  );
};

export default CaseStudyCard;

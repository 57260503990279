import Popup from "components/Popup";
import { useState } from "react";

const NewsSection = () => {
  const [showTrailer, setShowTrailer] = useState(false);

  return (
    <>
      <div id="news" className="-translate-y-20" />
      <section className=" xl:mx-16 xl:my-8 bg-[#333] w-auto xl:px-10 py-8 px-5 md:px-16 xl:rounded-2xl monitor:max-w-monitorWidth monitor:mx-auto">
        <div className="uppercase font-bold font-bebas-neue text-center text-5xl text-white mb-7">
          news
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-4 gap-4 overflow-hidden ">
          <div className="h-full w-full xl:col-span-2 xl:hidden ">
            <img
            loading="lazy"
              className="xl:h-full w-full xl:w-auto xl:object-cover object-contain"
              src={require("assets/homepage/news/c3-r1.jpg")}
              alt=""
            />
          </div>
          {/* First Column */}
          <div className="w-full col-span-1 flex flex-col justify-between gap-4 h-max xl:h-auto ">
            <div className="w-full ">
              <div className="text-white text-2xl ">
                <span className="italic font-bold">"Music for a While"&nbsp;</span>
                <span className="font-bold">
                  named Best Narrative Short at DC Independent Film Festival
                </span>
              </div>
              <br />
              <div className="text-white">
                On occasion we like to work on narrative projects. Last summer we were thankful for the opportunity to work with Bridging Stories on a very powerful film.
                <br />
                <br />
                We’re excited that it was also chosen as an official selection at Dances with Films NY, El Dorado Film Festival, and more!
              </div>
              <br />
              <br />
              <button
                onClick={() => setShowTrailer(true)}
                className="font-semibold text-white bg-orange-primary  px-3 py-2 md:rounded-lg uppercase block w-full md:w-max mx-auto xl:mx-0"
              >
                watch the trailer
              </button>
            </div>
            <img
              loading="lazy"
              className="w-full h-2/5 object-cover bg-bottom object-bottom"
              src={require("assets/homepage/news/c1-r2.jpg")}
              alt=""
            />
          </div>

          {/* Second Column */}
          <div className="xl:flex flex-col gap-4 justify-between items-center col-span-1 grid grid-cols-2 ">
            <img
              loading="lazy"
              className="w-full  h-full object-cover"
              src={require("assets/homepage/news/c2-r1.jpg")}
              alt=""
            />
            <img
              loading="lazy"
              className="w-full h-full object-cover"
              src={require("assets/homepage/news/c2-r2.jpg")}
              alt=""
            />
          </div>

          {/* Third Column */}
          <div className="h-full w-full xl:col-span-2 hidden xl:block">
            <img
              loading="lazy"
              className="h-full w-auto object-cover"
              src={require("assets/homepage/news/c3-r1.jpg")}
              alt=""
            />
          </div>
        </div>
      </section>
      <Popup isOpen={showTrailer} onClose={() => setShowTrailer(false)}>
        <iframe
          className="2xl:w-[1280px] 2xl:h-[675px] xl:w-[984.61px] xl:h-[519.23px] md:w-[673.68px] md:h-[355.26px] w-[341.33px] h-[180px]"
          src="https://www.youtube.com/embed/4K0eG6SJU7E"
          title='"Music for a While" Teaser Trailer'
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </Popup>
    </>
  );
};

export default NewsSection;
